import React from 'react';

const LinkOut = (props) => (
  props.href ?
  <a target="_blank" rel="noopener noreferrer" {...props}>
    {props.children}
  </a>
  :
  <a
    href={`https://www.google.com/search?q=${props.children}`}
    target="_blank" rel="noopener noreferrer"
    style={{textDecoration: 'line-through', color: 'inherit', cursor: 'help'}}>
    {props.children}
  </a>
);

export default LinkOut;
