import React from 'react';
import PropTypes from 'prop-types';
import './layout.css';

const Layout = ({children}) => {
  return (
    <React.Fragment>
      <main>{children}</main>
    </React.Fragment>
  );
}

const LayoutSplash = ({children}) => {
  return (
    <React.Fragment>
      <main className="splash">{children}</main>
    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout;
export { LayoutSplash };
